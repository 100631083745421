import React from 'react';
import { useIntl } from 'react-intl';

import CvSkillsSection from './CvSkillsSection';

import { ROLES } from 'constants/userConstants';

import './CvSkills.scss';

const CvSkills = ({
    skills,
    preferredStack,
    enterEditMode,
    enableEdit,
    displayMode
}) => {
    const intl = useIntl();

    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-skills-wrapper">
                <CvSkillsSection 
                    sectionTitle={intl.formatMessage({ id: "skills-text" })}
                    skills={skills}
                    enterEditMode={enterEditMode}
                    enableEdit={enableEdit}
                    isClient={isClient}
                />
            </div>
        </>
    )
};

export default CvSkills;
