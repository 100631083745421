import React from 'react';

import MissingInformation from '../MissingInformation';

import { ReactComponent as EditCVIcon } from 'assets/edit-candidate-cv-icon.svg';

const CvSkillsSection = ({
    enableEdit,
    enterEditMode,
    skills,
    isClient,
    sectionTitle,
    image,
}) => (
    <div className="cv-skills-section">
        <div className="header">
            <h1 className="title subheading-xxl color-dark-grey">
                {image && <img src={image} />} {sectionTitle}
            </h1>
            {enableEdit &&
                <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('skills')}>
                    <EditCVIcon />
                </div>
            }
        </div>
        {skills?.length > 0
            ?
            <div className="skills-container">
                {skills.map((skill, idx) =>
                    <div className={`skill-box subheading-m color-grey ${isClient ? 'client' : ''}`} key={`${idx}-${skill._id}`}>{skill.name}</div>
                )}
            </div>
            : isClient ? <div className="empty-category" /> : <MissingInformation />
        }
    </div>
);

export default CvSkillsSection;
